@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&family=Orbitron:wght@400..900&display=swap');

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Orbitron", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(9, 9, 11);
  color: #fff;
}

/* input:-webkit-autofill {
  background-color: red !important;
  box-shadow: 0 0 0px 1000px red inset !important;
  -webkit-text-fill-color: black !important; 
  border: 1px solid #ccc;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mainblock {
  height: calc(100vh - 80px);
}


.heading1 {
  color: white;
	-webkit-text-stroke: 1px #F8F8F8;
	text-shadow: 0px 1px 4px #23430C;
}


.text-gradient {
  background: linear-gradient(to right, #8b5cf6, #6366f1);
  background-clip: text; /* Standard property */
  -webkit-background-clip: text; /* WebKit compatibility */
  color: transparent; /* Ensures the gradient is visible through the text */
}